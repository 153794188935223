import { Input } from 'antd';
import cn from 'classnames';
import React, { useState } from 'react';

import './FloatInput.scss';

export type InputType = 'text' | 'number' | 'email' | 'password' | 'textarea' | 'phone';

type Props = {
  label?: string;
  value?: string; // Allowing value to be optional
  name: string;
  placeholder?: string;
  type?: InputType;
  required?: boolean;
  onChange?: (
    e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>,
  ) => void;
  children?: React.ReactNode;
  maxLength?: number;
};

const FloatInput = ({
  label,
  value = '', // Default value is set to an empty string
  name,
  placeholder,
  type = 'text',
  required,
  onChange,
  maxLength,
  ...rest
}: Props) => {
  const requiredMark = required ? <span className="text-error">*</span> : null;

  const [isFocused, setIsFocused] = useState(false);
  const hasValue = value.trim() !== ''; // Safely using trim now

  const finalPlaceholder =
    !label && required ? `${placeholder || ''} *` : placeholder;

  // Determine if the label should float
  const shouldFloat = (label && hasValue) || isFocused;

  return (
    <div className={cn('float-label', { 'has-value': shouldFloat })}>
      {label && (
        <label
          htmlFor={name}
          className={cn('label', { 'has-value': shouldFloat })}
        >
          {label} {requiredMark}
        </label>
      )}
      {type === 'textarea' ? (
        <Input.TextArea
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            if (!hasValue) setIsFocused(false);
          }}
          onChange={onChange}
          className="float-input"
          name={name}
          value={value}
          rows={4}
          placeholder={finalPlaceholder}
          maxLength={maxLength}
          {...rest}
        />
      ) : (
        <Input
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            if (!hasValue) setIsFocused(false);
          }}
          onChange={onChange}
          className="float-input"
          name={name}
          type={type}
          value={value}
          maxLength={maxLength}
          placeholder={finalPlaceholder}
          {...rest}
        />
      )}
    </div>
  );
};

export default FloatInput;
