import { Radio, RadioChangeEvent } from 'antd';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import './RadioCustom.scss';

type Props = {
  label?: string;
  description?: string;
  name?: string;
  value?: string;
  disabled?: boolean;
  checked?: boolean;
  required?: boolean;
  labelClass?: string;
  onChange?: (e: RadioChangeEvent) => void;
};

const RadioCustom = ({
  label,
  description,
  name,
  value,
  disabled,
  required,
  labelClass = '',
  checked,
  onChange,
  ...rest
}: Props) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked || false);

  useEffect(() => {
    setIsChecked(checked || false);
  }, [checked]);

  const handleChange = (e: RadioChangeEvent) => {
    setIsChecked(e.target.checked);
    if (onChange) {
      onChange(e);
    }
  };
  const requiredMark = required ? <span className="text-error">*</span> : null;

  return (
    <Radio
      checked={isChecked}
      onChange={handleChange}
      className={'customRadio'}
      disabled={disabled}
      name={name}
      value={value}
      {...rest}
    >
      <div className={'customRadioLabelBox'}>
        {label && (
          <div
            className={cn(
              'customRadioLabel',
              { [labelClass]: labelClass },
              labelClass,
            )}
          >
            {label} {requiredMark}
          </div>
        )}
        {description && (
          <div className={'customRadioDescription'}>{description}</div>
        )}
      </div>
    </Radio>
  );
};

export default RadioCustom;
