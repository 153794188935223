import { Input } from 'antd';
import cn from 'classnames';
import React, { useState } from 'react';

import './FloatTextarea.scss';

type Props = {
  label?: string;
  value?: string; // Make value optional and set a default
  name: string;
  placeholder?: string;
  required?: boolean;
  onChange?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  children?: React.ReactNode;
  maxLength?: number;
  rows?: number;
};

const { TextArea } = Input;

const FloatTextarea = ({
  label,
  value = '', // Set default value to an empty string
  name,
  placeholder,
  required,
  onChange,
  maxLength,
  rows = 6,
  ...rest
}: Props) => {
  const requiredMark = required ? <span className="text-error">*</span> : null;

  const [isFocused, setIsFocused] = useState(false);
  const hasValue = value.trim() !== ''; // Safely calling trim

  const finalPlaceholder =
    !label && required ? `${placeholder || ''} *` : placeholder;

  // Determine if the label should float
  const shouldFloat = (label && hasValue) || isFocused;

  return (
    <div className={cn('float-label-textarea', { 'has-value': shouldFloat })}>
      {label && (
        <label
          htmlFor={name}
          className={cn('label', { 'has-value': shouldFloat })}
        >
          {label} {requiredMark}
        </label>
      )}
      <TextArea
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          if (!hasValue) setIsFocused(false);
        }}
        onChange={onChange}
        className="float-input"
        name={name}
        rows={rows}
        maxLength={maxLength}
        value={value}
        placeholder={finalPlaceholder}
        {...rest}
      />
    </div>
  );
};

export default FloatTextarea;
