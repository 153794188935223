import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { z } from 'zod';

const directusApiUri = process.env.NEXT_PUBLIC_DIRECTUS_API_URL;
const assetsUri = process.env.NEXT_PUBLIC_ASSETS_URL || `${directusApiUri}/assets`;
const downloadAssetsUri = process.env.NEXT_PUBLIC_DOWNLOAD_ASSETS_URL || `${directusApiUri}/assets`;
const axiosCacheSeconds = process.env.AXIOS_CACHE_SECONDS && parseInt(process.env.AXIOS_CACHE_SECONDS) || 0;

const directusService =
  typeof window === 'undefined' && axiosCacheSeconds
    ? setupCache( // setup a short cache when used on the server
        axios.create({
          baseURL: directusApiUri,
        }),
        {
          headerInterpreter: () => axiosCacheSeconds,
        },
      )
    : axios.create({ baseURL: directusApiUri });

directusService.interceptors.response.use(
  (response) => {
    if (!response.data && !response) {
      return Promise.reject(response);
    }
    return response.data ? response.data : response;
  },
  (error) => {
    if (typeof window === 'undefined') {
      console.error(error);
    }
    return Promise.reject(error);
  },
);

/**
 * Returns a list field names based on the schema we want to fetch
 * 
 * @param schema The zod schema
 * @returns 
 */
const zodKeys = <T extends z.ZodTypeAny>(schema: T, maxDepth = 5): string[] => {
  if (maxDepth < 0) {
    return [];
  }

  // make sure schema is not null or undefined
  if (schema === null || schema === undefined) return [];
  // check if schema is nullable or optional
  if (schema instanceof z.ZodNullable || schema instanceof z.ZodOptional)
    return zodKeys(schema.unwrap(), maxDepth);
  if (schema instanceof z.ZodLazy)
    return zodKeys(schema.schema, maxDepth);
  // check if schema is an array
  if (schema instanceof z.ZodArray) return zodKeys(schema.element, maxDepth);
  // check if discrimated union, if so, make a union of all the fields instead
  if (schema instanceof z.ZodDiscriminatedUnion) {
    const options = schema.options;
    const union = z.union(options);

    return zodKeys(union, maxDepth);
  }
  // check if the type is a union of types
  if (schema instanceof z.ZodUnion) {
    const keys = new Set<string>();

    schema.options.map((type) => zodKeys(type, maxDepth).forEach((key) => keys.add(key)))
    return Array.from(keys);
  }
  // check if schema is an object
  if (schema instanceof z.ZodObject) {
    // get key/value pairs from schema
    const entries = Object.entries(schema.shape);
    // loop through key/value pairs

    return entries.flatMap(([key, value]) => {
      // get nested keys
      const nested =
        value instanceof z.ZodType
          ? zodKeys(value, maxDepth - 1).map((subKey) => `${key}.${subKey}`)
          : [];
      // return nested keys

      return nested.length ? nested : key;
    });
  }
  // return empty array
  return [];
};

/**
 * Due to request size limits, we can't list every single field individually,
 * this method takes multiple fields like [`product.name`, `product.id`, `product.category.id`] and 
 * combines them into [`product.*`, `product.category.*`]
 * 
 * @param fields 
 */
const combineFields = (fields: string[]): string[] => {
  const result = new Set<string>();

  for (const field of fields) {
    const fieldParts = field.split('.');

    if (fieldParts.length > 1) {
      // part after last . to *, then add to the set, duplicates will be discarded
      fieldParts[fieldParts.length-1] = '*';
      result.add(fieldParts.join('.'));
    } else {
      result.add(field);
    }
  }
  return Array.from(result);
}

export {
  directusService,
  directusApiUri,
  assetsUri,
  downloadAssetsUri,
  zodKeys,
  combineFields,
};
