'use client';
import { Button, ButtonProps } from 'antd';
import cn from 'classnames';
import React from 'react';

import './ButtonCustom.scss';

type Props = ButtonProps & {
  btnText?: string;
  className?: string;
  extraClass?: string;
  moduleClass?: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  role?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  fitWidth?: boolean;
  href?: string;
  htmlType?: 'button' | 'submit' | 'reset';
  newTab?: boolean | null;
};

const ButtonCustom = ({
  btnText,
  className = '',
  extraClass = '',
  moduleClass = '',
  children,
  onClick,
  role = 'button',
  htmlType,
  disabled,
  fullWidth,
  fitWidth = false,
  href,
  newTab = false,
  ...otherProps
}: Props) => {
  return (
    <Button
      role={role}
      href={href}
      target={newTab ? '_blank' : '_self'}
      className={cn(
        'btn',
        {
          [className]: className,
          [extraClass]: extraClass,
        },
        !className && 'btnPrimary',
        moduleClass,
        extraClass,
        fullWidth && 'fullWidth',
        fitWidth && 'fitWidth',
        disabled && 'disabled',
      )}
      disabled={disabled}
      htmlType={htmlType}
      onClick={onClick}
      {...otherProps}
    >
      <span>{children ? children : btnText || ''}</span>
    </Button>
  );
};

export default ButtonCustom;
