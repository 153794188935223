import { Turnstile } from '@marsidev/react-turnstile';

type Props = {
  onSuccess: (token: string) => void;
  onError?: () => void;
  onLoad?: () => void;
};

const CloudflareTurnstile = ({ onSuccess, onError, onLoad }: Props) => {
  const sitekey = process.env.NEXT_PUBLIC_TURNSTILE_SITEKEY || '';
  const enabled = Boolean(sitekey);

  if (!enabled) {
    return null;
  }

  return (
    <Turnstile
      options={{
        theme: 'light',
      }}
      siteKey={sitekey}
      onSuccess={onSuccess}
      onError={onError}
      onLoad={onLoad}
    />
  );
};

export default CloudflareTurnstile;
