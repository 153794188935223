import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import cn from 'classnames';
import React from 'react';

import './CheckBoxCustom.scss';

type Props = {
  label?: string;
  description?: string;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
  labelClass?: string;
  onChange?: (e: CheckboxChangeEvent) => void;
};

const CheckBoxCustom = ({
  label,
  description,
  name,
  disabled,
  required,
  indeterminate,
  labelClass = '',
  checked,
  onChange,
  ...rest
}: Props) => {
  const handleChange = (e: CheckboxChangeEvent) => {
    if (onChange) {
      onChange(e);
    }
  };

  const requiredMark = required ? <span className="text-error">*</span> : null;

  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      className={'customCheckbox'}
      disabled={disabled}
      name={name}
      indeterminate={indeterminate}
      {...rest}
    >
      <div className={'customCheckboxLabelBox'}>
        {label && (
          <div className={'customCheckboxLabelContainer'}>
            <div
              className={cn(
                'customCheckboxLabel',
                { [labelClass]: labelClass },
                labelClass,
              )}
              dangerouslySetInnerHTML={{ __html: label }}
            ></div>
            {requiredMark}
          </div>
        )}
        {description && (
          <div
            className={'customCheckboxDescription'}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </Checkbox>
  );
};

export default CheckBoxCustom;
